import React, {useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PrivateRoute = () => {
    const auth = useAuth();

    useEffect(() => {
        //console.log('PrivateRoute auth', auth);
        //console.log('PrivateRoute atoken/rtoken', sessionStorage.getItem("atoken"), localStorage.getItem("rtoken"));        
      }, []);

      
      if (!auth.token) return <Navigate to="/login" />;
      return <Outlet />;
  };
  
  export default PrivateRoute;