import React, { createContext, useContext, useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();
import axiosDut from "../lib/axios"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => {
    // const [user, setUser] = useState(null);
    const [token, setToken] = useState(sessionStorage.getItem("atoken") || "");
    const navigate = useNavigate();
    const [flagRefresh, setFlagRefresh] = useState(!sessionStorage.getItem("atoken") && localStorage.getItem("rtoken"));

    //console.log('AuthProvider init atoken/rtoken/flagRefresh', sessionStorage.getItem("atoken"), localStorage.getItem("rtoken"), token, flagRefresh);


    useEffect(() => {
      //console.log('AuthProvider useEffect atoken/rtoken', sessionStorage.getItem("atoken"), localStorage.getItem("rtoken"), token);

      if(flagRefresh){

        let payload = {
          'origin': 'tg',
          'site': 'my.naseligere.ru',
          'data':{
            'token':localStorage.getItem("rtoken"),
          }
      }

      axiosDut.post("users/refresh", payload)
          .then(res =>
          {
            if(res.data && res.data.ok){
              sessionStorage.setItem("atoken", res.data.data['access-token']);
              localStorage.setItem("rtoken", res.data.data['refresh-token']);
              setToken(res.data.data['access-token']);
              setFlagRefresh(false);
            }
            else{
              console.log('AUTH ERROR', res);
              setFlagRefresh(false);              
            }
          })
          .catch(error => {
              console.log("error axios", error);
              setFlagRefresh(false);              
          });  
      }
    }, []);    

    const logIn = (token) => {
        setToken(token);
    };
  
    const logOut = () => {
        //console.log('logOut');
        // setUser(null);
        setToken("");
        sessionStorage.removeItem("atoken");
        localStorage.removeItem("rtoken");
        navigate("/login");
    };
    if(flagRefresh){
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
        <AuthContext.Provider value={{ token, logIn, logOut }}>
          {children}
        </AuthContext.Provider>
      );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
  };