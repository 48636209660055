import axios from "axios";

const axiosDefaultOptions =
{
    baseURL: "https://x.naseligere.ru/api/",
    headers: {
        "Content-Type": "application/json"
    },
};

let axiosInstance = axios.create(axiosDefaultOptions);

axiosInstance.interceptors.request.use(function (config)
{
    let atoken = sessionStorage.getItem("atoken");
    if (atoken){
        console.log('axios atoken', atoken);
        config.headers["Authorization"] = 'Bearer ' + atoken;
    }
    else{
        let rtoken = localStorage.getItem("rtoken");
        if (rtoken){
            console.log('axios rtoken', rtoken);
            config.headers["Authorization"] = 'Bearer ' + rtoken;
        }
    
    }

    return config;
});

export default axiosInstance;