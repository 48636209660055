
import React, { useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const Home = () => {

  useEffect(() => {
    //console.log('Home atoken/rtoken', sessionStorage.getItem("atoken"), localStorage.getItem("rtoken"));
  }, []);    


  return (
    <React.Fragment>
      <CssBaseline />
        <AppBar className='dut-appbar login'>
          <Toolbar>
            <Typography variant="h6" component="div">
              MY.NASELIGERE.RU
            </Typography>
          </Toolbar>
        </AppBar>
      <Toolbar />

      <Container className='dut-container home'>
        <Box sx={{ my: 2 }} className='dut-box home'>
          <h1>Дашборд</h1>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Home;