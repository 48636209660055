import React, {useEffect} from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axiosDut from "../lib/axios"
import { useAuth } from "../context/AuthContext";

const Auth = () => {
  const [searchParams] = useSearchParams()  
  const navigate = useNavigate();
  const auth = useAuth();  

  useEffect(() => {
        let payload = {
            'origin': 'tg',
            'site': 'my.naseligere.ru',
            'data':{
              'id':searchParams.get('id'),
              'first_name':searchParams.get('first_name'),
              'last_name':searchParams.get('last_name'),
              'username':searchParams.get('username'),
              'auth_date':searchParams.get('auth_date'),
              'hash':searchParams.get('hash'),
              'photo_url':searchParams.get('photo_url')              
            }
        }

        axiosDut.post("users/signin", payload)
            .then(res =>
            {
              if(res.data && res.data.ok){
                sessionStorage.setItem("atoken", res.data.data['access-token']);
                localStorage.setItem("rtoken", res.data.data['refresh-token']);
                auth.logIn(res.data.data['access-token']);
                let target=res.data.data.first_page ? res.data.data.first_page : '/home';
                //console.log('Auth atoken/rtoken', sessionStorage.getItem("atoken"), localStorage.getItem("rtoken"));
                navigate(target);
              }
              else{
                console.log('AUTH ERROR', res);
                navigate('/login');
              }
            })
            .catch(error => {
                console.log("error axios", error);
                navigate('/login');
            });    
  }, []);

  return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
  );
};

export default Auth;