
import React, { useEffect} from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const AdvertEditor = () => {
  const params = useParams();
  const advertId = params.id;

  useEffect(() => {
    console.log('AdvertEditor useEffect');
  }, []);    


  return (
    <React.Fragment>
      <CssBaseline />
        <AppBar className='dut-appbar login'>
          <Toolbar>
            <Typography variant="h6" component="div">
              MY.NASELIGERE.RU
            </Typography>
          </Toolbar>
        </AppBar>
      <Toolbar />

      <Container className='dut-container home'>
        <Box sx={{ my: 2 }} className='dut-box home'>
          <h1>Editor {advertId}</h1>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default AdvertEditor;