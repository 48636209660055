import React, {useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

//import { useAuth } from "../context/AuthContext";

const Login = () => {
  const telegramWrapperRef = useRef(null);

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
    scriptElement.setAttribute('data-telegram-login', 'NaseligereBot');
    scriptElement.setAttribute('data-size', 'large');
    scriptElement.setAttribute('data-auth-url', 'https://my.naseligere.ru/auth');
    scriptElement.setAttribute('data-request-access', 'write');
    scriptElement.async = true;
  
    telegramWrapperRef.current.appendChild(scriptElement);
  }, []);

  // const userData = useAuth();      
  // const [input, setInput] = useState({
  //   username: "",
  //   password: "",
  // });


  // const handleSubmitEvent = (e) => {
  //   e.preventDefault();

  //   if (input.username !== "" && input.password !== "") {
  //     userData.logIn(input.username, input.password).then((value) => {
  //       if(!value)
  //         userData.logOut();
  //     });

  //     //dispatch action from hooks
  //   }
  //   else{
  //     alert("please provide a valid input");
  //   }
  // };  
  
  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setInput((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

return (
    <React.Fragment>
      <CssBaseline />
        <AppBar className='dut-appbar login'>
          <Toolbar>
            <Typography variant="h6" component="div">
              MY.NASELIGERE.RU
            </Typography>
          </Toolbar>
        </AppBar>
      <Toolbar />

      <Container className='dut-container login'>
        <Box sx={{ my: 2 }} className='dut-box login'>
          <h1>Вход</h1>
          <div className='tg-widget' ref={telegramWrapperRef}></div>       
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Login;