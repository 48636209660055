import React  from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./context/AuthContext";
import PrivateRoute from './components/PrivateRoute';
import Home from './components/Home';
import Auth from './components/Auth';
import ToHome from './components/ToHome';
import Login from './components/Login';
import AdvertEditor from './components/AdvertEditor'

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/auth" element={<Auth />} />
            <Route element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
            </Route>
            <Route exact path="/" element={<ToHome/>}/>            
            <Route element={<PrivateRoute />}>
              <Route path="/advert" element={<AdvertEditor />} />
            </Route>          
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
